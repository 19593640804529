
/**
 * @name: detail
 * @author: Gzm
 * @date: 2023-05-31 14:29
 * @description：详情信息组件
 * @update: 2023-05-31 14:29
 */
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {IProduct} from "@/apis/product/types";
import config from "@/config";
import {ITour} from "@/apis/travelManage/types";

@Component({})
export default class ProductBasic extends Vue {
  @Prop(Object) data!: Object;
  config = config;
  // 新增修改表单
  modelForm: ITour = {}
  tourDetails:any = ""

  /**
   * 下一步
   * @param number
   *
   */
  handleNext(tab: string) {
    this.$emit('Next',tab)
  }

  handleAdd(){
    this.$emit('Add',this.tourDetails)
  }

  get computedData(){
    return this.data;
  }

  @Watch('computedData')
  onChangeValue(newVal: any, oldVal: any){
    console.log(newVal.tourDetails,'tourDetails')
    this.tourDetails = newVal.tourDetails
  }

  created() {
  }
}
